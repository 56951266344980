import { RouteRecordRaw } from 'vue-router';

const rotasAplicacao: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Aplicacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'sem-privilegio',
        name: 'SemPrivilegio',
        meta: {
          titulo: 'Você não possui autorização!',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "core" */ '@/core/views/SemPrivilegio.vue'),
      },
      {
        path: 'configuracoes',
        name: 'Configuracoes',
        meta: {
          titulo: 'Configurações',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/Configuracoes.vue'),
      },
      {
        path: 'configuracoes-emails',
        name: 'ConfiguracoesEmails',
        meta: {
          titulo: 'Configurações de E-mails',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/ConfiguracoesEmails.vue'),
      },
      {
        path: 'meu-negocio',
        name: 'MeuNegocio',
        meta: {
          titulo: 'Meu Negócio',
          identificadorPermissao: 'MEU_NEGOCIO',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/MeuNegocio.vue'),
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        meta: {
          titulo: 'Usuários',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioListagem.vue'),
      },
      {
        path: 'usuarios/cadastrar',
        name: 'UsuarioCadastroCriar',
        meta: {
          titulo: 'Cadastrar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/:codigo',
        name: 'UsuarioCadastroEditar',
        meta: {
          titulo: 'Editar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/meus-dados',
        name: 'UsuarioMeusDados',
        meta: {
          titulo: 'Meus Dados',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/MeusDados.vue'),
      },
      {
        path: 'precos',
        name: 'Precos',
        meta: {
          titulo: 'Preços',
          identificadorPermissao: 'PRECOS',
        },
        component: () => import(/* webpackChunkName: "precos" */ '@/views/Precos/PrecoListagem.vue'),
      },
      {
        path: 'precos/cadastrar',
        name: 'PrecoCadastroCriar',
        meta: {
          titulo: 'Cadastrar Preço',
          identificadorPermissao: 'PRECOS',
        },
        component: () => import(/* webpackChunkName: "precos" */ '@/views/Precos/PrecoCadastro.vue'),
      },
      {
        path: 'precos/:codigo',
        name: 'PrecoCadastroEditar',
        meta: {
          titulo: 'Editar Preço',
          identificadorPermissao: 'PRECOS',
        },
        component: () => import(/* webpackChunkName: "precos" */ '@/views/Precos/PrecoCadastro.vue'),
      },
      {
        path: 'paginas',
        name: 'Paginas',
        meta: {
          titulo: 'Páginas',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "paginas" */ '@/views/Paginas/PaginaListagem.vue'),
      },
      {
        path: 'paginas/nova',
        name: 'PaginaCadastroCriar',
        meta: {
          titulo: 'Nova Página',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "paginas" */ '@/views/Paginas/PaginaCadastro.vue'),
      },
      {
        path: 'paginas/:codigo',
        name: 'PaginaCadastroEditar',
        meta: {
          titulo: 'Editar Página',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "paginas" */ '@/views/Paginas/PaginaCadastro.vue'),
      },
      {
        path: 'posts',
        name: 'Posts',
        meta: {
          titulo: 'Posts',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "posts" */ '@/views/Posts/PostListagem.vue'),
      },
      {
        path: 'posts/novo',
        name: 'PostCadastroCriar',
        meta: {
          titulo: 'Novo Post',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "posts" */ '@/views/Posts/PostCadastro.vue'),
      },
      {
        path: 'posts/:codigo',
        name: 'PostCadastroEditar',
        meta: {
          titulo: 'Editar Post',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "posts" */ '@/views/Posts/PostCadastro.vue'),
      },
      {
        path: 'landing-pages',
        name: 'LandingPages',
        meta: {
          titulo: 'Landing Pages',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "landings" */ '@/views/LandingPages/LandingPageListagem.vue'),
      },
      {
        path: 'landing-pages/nova',
        name: 'LandingPageCadastroCriar',
        meta: {
          titulo: 'Nova Landing Page',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "landings" */ '@/views/LandingPages/LandingPageCadastro.vue'),
      },
      {
        path: 'landing-pages/:codigo',
        name: 'LandingPageCadastroEditar',
        meta: {
          titulo: 'Editar Landing Page',
          identificadorPermissao: 'CONTEUDOS',
        },
        component: () => import(/* webpackChunkName: "landings" */ '@/views/LandingPages/LandingPageCadastro.vue'),
      },
      {
        path: 'categorias',
        name: 'Categorias',
        meta: {
          titulo: 'Categorias',
          identificadorPermissao: 'CATEGORIAS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Categorias/CategoriaListagem.vue'),
      },
      {
        path: 'categorias/cadastrar',
        name: 'CategoriaCadastroCriar',
        meta: {
          titulo: 'Cadastrar Categoria',
          identificadorPermissao: 'CATEGORIAS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Categorias/CategoriaCadastro.vue'),
      },
      {
        path: 'categorias/:codigo',
        name: 'CategoriaCadastroEditar',
        meta: {
          titulo: 'Editar Categoria',
          identificadorPermissao: 'CATEGORIAS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Categorias/CategoriaCadastro.vue'),
      },
      {
        path: 'marcas',
        name: 'Marcas',
        meta: {
          titulo: 'Marcas',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaListagem.vue'),
      },
      {
        path: 'marcas/cadastrar',
        name: 'MarcaCadastroCriar',
        meta: {
          titulo: 'Cadastrar Marca',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaCadastro.vue'),
      },
      {
        path: 'marcas/:codigo',
        name: 'MarcaCadastroEditar',
        meta: {
          titulo: 'Editar Marca',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaCadastro.vue'),
      },
      {
        path: 'caracteristicas',
        name: 'Caracteristicas',
        meta: {
          titulo: 'Características',
          identificadorPermissao: 'CARACTERISTICAS',
        },
        component: () => import(/* webpackChunkName: "caracteristicas" */ '@/views/Caracteristicas/CaracteristicaListagem.vue'),
      },
      {
        path: 'caracteristicas/cadastrar',
        name: 'CaracteristicaCadastroCriar',
        meta: {
          titulo: 'Cadastrar Característica',
          identificadorPermissao: 'CARACTERISTICAS',
        },
        component: () => import(/* webpackChunkName: "caracteristicas" */ '@/views/Caracteristicas/CaracteristicaCadastro.vue'),
      },
      {
        path: 'caracteristicas/:codigo',
        name: 'CaracteristicaCadastroEditar',
        meta: {
          titulo: 'Editar Característica',
          identificadorPermissao: 'CARACTERISTICAS',
        },
        component: () => import(/* webpackChunkName: "caracteristicas" */ '@/views/Caracteristicas/CaracteristicaCadastro.vue'),
      },
      {
        path: 'produtos',
        name: 'Produtos',
        meta: {
          titulo: 'Produtos',
          identificadorPermissao: 'PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "produtos" */ '@/views/Produtos/ProdutoListagem.vue'),
      },
      {
        path: 'produtos/cadastrar',
        name: 'ProdutoCadastroCriar',
        meta: {
          titulo: 'Cadastrar Produto',
          identificadorPermissao: 'PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "produtos" */ '@/views/Produtos/ProdutoCadastro.vue'),
      },
      {
        path: 'produtos/:codigo',
        name: 'ProdutoCadastroEditar',
        meta: {
          titulo: 'Editar Produto',
          identificadorPermissao: 'PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "produtos" */ '@/views/Produtos/ProdutoCadastro.vue'),
      },
      {
        path: 'clientes',
        name: 'Clientes',
        meta: {
          titulo: 'Clientes',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteListagem.vue'),
      },
      {
        path: 'clientes/cadastrar',
        name: 'ClienteCadastroCriar',
        meta: {
          titulo: 'Cadastrar Cliente',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteCadastro.vue'),
      },
      {
        path: 'clientes/:codigo',
        name: 'ClienteCadastroEditar',
        meta: {
          titulo: 'Editar Cliente',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteCadastro.vue'),
      },
      {
        path: 'pedidos',
        name: 'Pedidos',
        meta: {
          titulo: 'Pedidos',
          identificadorPermissao: 'PEDIDOS',
        },
        component: () => import(/* webpackChunkName: "pedidos" */ '@/views/Pedidos/PedidoListagem.vue'),
      },
      {
        path: 'pedidos/:numeroIdentificacao',
        name: 'PedidoDetalheEditar',
        meta: {
          titulo: 'Detalhes Pedido',
          identificadorPermissao: 'PEDIDOS',
        },
        component: () => import(/* webpackChunkName: "pedidos" */ '@/views/Pedidos/PedidoDetalhe.vue'),
      },
      {
        path: 'formas-entregas',
        name: 'FormasEntregas',
        meta: {
          titulo: 'Formas de Entregas',
          identificadorPermissao: 'FORMAS_ENTREGAS',
        },
        component: () => import(/* webpackChunkName: "formas-entregas" */ '@/views/FormasEntregas/FormaEntregaListagem.vue'),
      },
      {
        path: 'formas-entregas/cadastrar',
        name: 'FormaEntregaCadastroCriar',
        meta: {
          titulo: 'Nova Forma de Entrega',
          identificadorPermissao: 'FORMAS_ENTREGAS',
        },
        component: () => import(/* webpackChunkName: "formas-entregas" */ '@/views/FormasEntregas/FormaEntregaCadastro.vue'),
      },
      {
        path: 'formas-entregas/:codigo',
        name: 'FormaEntregaCadastroEditar',
        meta: {
          titulo: 'Configurar Forma de Entrega',
          identificadorPermissao: 'FORMAS_ENTREGAS',
        },
        component: () => import(/* webpackChunkName: "formas-entregas" */ '@/views/FormasEntregas/FormaEntregaCadastro.vue'),
      },
      {
        path: 'formas-pagamentos',
        name: 'FormasPagamentos',
        meta: {
          titulo: 'Formas de Pagamentos',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoListagem.vue'),
      },
      {
        path: 'formas-pagamentos/cadastrar',
        name: 'FormaPagamentoCadastroCriar',
        meta: {
          titulo: 'Nova Forma de Pagamento',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoCadastro.vue'),
      },
      {
        path: 'formas-pagamentos/:codigo',
        name: 'FormaPagamentoCadastroEditar',
        meta: {
          titulo: 'Configurar Forma de Pagamento',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoCadastro.vue'),
      },
      {
        path: 'regras-fretes',
        name: 'RegrasFretes',
        meta: {
          titulo: 'Regras de Fretes',
          identificadorPermissao: 'REGRAS_FRETES',
        },
        component: () => import(/* webpackChunkName: "regras-fretes" */ '@/views/RegrasFretes/RegraFreteListagem.vue'),
      },
      {
        path: 'regras-fretes/cadastrar',
        name: 'RegraFreteCadastroCriar',
        meta: {
          titulo: 'Nova Regra de Frete',
          identificadorPermissao: 'REGRAS_FRETES',
        },
        component: () => import(/* webpackChunkName: "regras-fretes" */ '@/views/RegrasFretes/RegraFreteCadastro.vue'),
      },
      {
        path: 'regras-fretes/:codigo',
        name: 'RegraFreteCadastroEditar',
        meta: {
          titulo: 'Configurar Regra de Frete',
          identificadorPermissao: 'REGRAS_FRETES',
        },
        component: () => import(/* webpackChunkName: "regras-fretes" */ '@/views/RegrasFretes/RegraFreteCadastro.vue'),
      },
      {
        path: 'regras-precos',
        name: 'RegrasPrecos',
        meta: {
          titulo: 'Regras de Preços',
          identificadorPermissao: 'REGRAS_PRECOS',
        },
        component: () => import(/* webpackChunkName: "regras-precos" */ '@/views/RegrasPrecos/RegraPrecoListagem.vue'),
      },
      {
        path: 'regras-precos/cadastrar',
        name: 'RegraPrecoCadastroCriar',
        meta: {
          titulo: 'Nova Regra de Preço',
          identificadorPermissao: 'REGRAS_PRECOS',
        },
        component: () => import(/* webpackChunkName: "regras-precos" */ '@/views/RegrasPrecos/RegraPrecoCadastro.vue'),
      },
      {
        path: 'regras-precos/:codigo',
        name: 'RegraPrecoCadastroEditar',
        meta: {
          titulo: 'Configurar Regra de Preço',
          identificadorPermissao: 'REGRAS_PRECOS',
        },
        component: () => import(/* webpackChunkName: "regras-precos" */ '@/views/RegrasPrecos/RegraPrecoCadastro.vue'),
      },
    ],
  },
];

export default rotasAplicacao;
